<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>

      <section id="legal" class="bg_lighgrey">
        <div class="container">
          <div class="row">

            <div class="col-12 aos-init" data-aos="fade-up" data-aos-duration="1000">
              <h3 class="title_xl title_lightblue">
                POLITIKA KOLAČIĆA
              </h3>
              <p class="text_l mt-5">

                Poštovani,
                Na internet prezentaciji i platformi Serbian Researchers (postojeće na internet adresi: https://www.serbianresearchers.com/) koriste se kolačići (eng. cookies) i druge slične
                tehnologije radi sticanja uvida o načinu korišćenja Platforme, obezbeđivanja njenih
                funkcionalnosti, unapređenja korisničkog iskustva i praćenja statistike i analitike
                saobraćaja.
                <br><br>
                Pred vama se nalazi Politika kolačića kojom se reguliše upotreba kolačića i drugih
                sličnih tehnologija. Molimo vas da pažljivo pročitate ovu Politiku kolačića imajući
                u vidu da se ona primenjuje na vas, odnosno na vaše korišćenje Platforme.
                <br><br>
                Pored Politike kolačića, i zajedno sa njom, primenjuju se i <router-link to="/terms-and-conditions">Pravila i uslovi
                korišćenja</router-link> (u daljem tekstu: <b>Pravila</b>) kojima se uređuje korišćenje Platforme, i
                <router-link to="/privacy-policy">Politika privatnosti</router-link> kojom se uređuje prikupljanje, obrada, korišćenje i zaštita
                ličnih podataka, u skladu sa relevantnim propisima R. Srbije.
                <br><br>
                Svaka poseta Platformi ili registracijom znači da ste upoznati, odnosno pročitali
                <router-link to="/terms-and-confitions">Pravila</router-link>, <router-link to="/privacy-policy">Politiku privatnosti</router-link> i Politiku kolačića, razumeli predmet njihovog
                uređivanja i da se slažete sa njima u celosti. Ukoliko, pak, oni za vas nisu prihvatljivi,
                molimo vas da ne koristite Platformu.
              </p>

              <h4 class="title_s title_lightblue mt-4">
                I	ŠTA SU KOLAČIĆI I ČEMU SLUŽE
              </h4>

              <p class="text_l mt-3">
                Kolačići su male tekstualne datoteke koje mogu koristiti veb sajtovi da učine korisničko iskustvo efikasnijim. Oni se skladište na korisnikovom računaru ili mobilnom telefonu nakon što korisnik pristupi veb sajtu. Internet pretraživač koji korisnik koristi (Chrome, Firefox, Internet Explorer itd.) čuva kolačiće nakon njihove instalacije.
                <br><br>
                Kolačići pomažu da veb sajt bude upotrebljiv omogućavajući osnovne funkcije kao što su navigacija na stranici i pristup bezbednim delovima veb sajta.
                <br><br>
                Oni, takođe, pružaju informacije o načinu korišćenja veb sajta (datum i vreme pristupa, posećene stranice, vreme provedeno na veb sajtu i dr.). Ovi podaci su često anonimizovani i koriste se u statističke svrhe i analitiku saobraćaja.
                <br><br>
                Kolačići omogućavaju veb sajtu i da „zapamti“, odnosno sačuva pojedine informacije o korisnikovom pristupu određenim funkcionalnostima, i na taj način prepozna korisnika prilikom sledećeg korišćenja radi omogućavanja efikasnijeg korišćenja tih funkcionalnosti.
              </p>

              <h4 class="title_s title_lightblue mt-4">
                II	KATEGORIJE KOLAČIĆA
              </h4>

              <p class="text_l mt-3">
                Postoje 2 glavne kategorije kolačića:
                <br><br>
                1) kolačići po sesiji - nazivaju se i prolaznim kolačićima, ovi kolačići se brišu kada korisnik zatvori veb pretraživač. Kolačić po sesiji se čuva u privremenoj memoriji i ne zadržava se nakon zatvaranja pretraživača ili nakon što se korisnik odjavi;
                <br><br>
                2) trajni kolačići – skladište u uređaju korisnika i čuvaju određeno vreme. Pomažu veb sajtu da pamti podatke i podešavanja korisnika kada korisnik kasnije ponovo poseti veb sajt. Ovo rezultira bržim i povoljnijim pristupom jer, na primer, korisnik ne mora ponovo da se prijavljuje. Trajni kolačići mogu takođe da sadrže kolačiće smeštene sa drugog veb sajta, a ne onog koji korisnik trenutno posećuje (poznati i kao „kolačići trećih lica“) - koja se anonimno mogu koristiti za pamćenje korisničkih podešavanja.

              </p>

              <h4 class="title_s title_lightblue mt-4">
                III	SAGLASNOST ZA KORIŠĆENjE KOLAČIĆA
              </h4>

              <p class="text_l mt-3">
                Načelno, korišćenjem veb sajta smatra se da je korisnik dao saglasnost na upotrebu kolačića. U pogledu neophodnih, odnosno obaveznih kolačića saglasnost nije neophodna imajući u vidu da su ovi kolačići potrebni za funkcionisanje veb sajta.
                <br><br>
                U pogledu ostalih kolačića, korisnik ima pravo da uskrati saglasnost i blokira korišćenje neželjenih kolačića. Više o načinu blokiranja videti u glavi V ove Politike kolačića.
                <br><br>
                Blokiranje određenih kolačića može promeniti kvalitet korisničkog iskustva na veb sajtu ili onemogućiti normalno funkcionisanje određenih delova veb sajta.

              </p>

              <h4 class="title_s title_lightblue mt-4">
                IV	KOLAČIĆI KOJI SE KORISTE NA PLATFORMI

              </h4>

              <p class="text_l mt-3">
                Kolačići koji se koriste na internet prezentaciji i platformi Serbian Researchers su pretežno neophodni za operativni rad i funkcionisanje Platforme. Takođe, koriste se i kolačići u statističke svrhe i za potrebe analitike saobraćaja. Više podataka u tabeli u nastavku:
                <br><br>
                <img class="img-fluid" :src="require(`@/assets/cookies.png`)">
              </p>

              <h4 class="title_s title_lightblue mt-4">
                V	BLOKIRANjE KOLAČIĆA
              </h4>

              <p class="text_l mt-3">
                Obično je moguće zaustaviti internet pretraživač da prihvata kolačiće ili zaustaviti prihvatanje kolačića sa određenog sajta.
                <br><br>
                Svi moderni pretraživači omogućavaju korisniku promenu postavki kolačića. Ova podešavanja se obično nalaze u meniju „opcije“ ili „postavke“ korisnikovog pretraživača. Takođe, korisnik bi trebalo da koristi opciju „Pomoć“ u svom pretraživaču za više detalja.
                <br><br>
                Više o načinu blokiranja (i postavkama) kolačića za određene pretraživače možete videti na linkovima u nastavku:
                <br><br>
                Internet Explorer<br>
                https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                <br><br>
                Firefox<br>
                https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies
                <br><br>
                Chrome<br>
                https://support.google.com/chrome/answer/95647?hl=en-GB
                <br><br>
                Safari<br>
                https://support.apple.com/kb/PH5042


              </p>

              <h4 class="title_s title_lightblue mt-4">
                VI	VIŠE O KOLAČIĆIMA...
              </h4>

              <p class="text_l mt-3">
                Za više informacije o kolačićima verujemo da sledeći link može biti koristan:
                https://www.allaboutcookies.org

              </p>

              <h4 class="title_s title_lightblue mt-4">
                VII	PODACI O RUKOVAOCU I KONTAKT

              </h4>

              <p class="text_l mt-3">
                Naziv: Udruženje SRPSKI ISTRAŽIVAČI<br>
                Adresa: Gospodar Jovanova 43, 11000 Beograd, Srbija<br>
                MB: 28321406<br>
                PIB: 112436772
                <br><br>
                Korisnik može kontaktirati Udruženje putem „obične“ pošte na gore navedenu adresu ili putem <router-link to="/contact" target="_blank">Kontakt forme</router-link>.

              </p>

              <h4 class="title_s title_lightblue mt-4">
                VIII	ZAVRŠNE ODREDBE
              </h4>

              <p class="text_l mt-3">
                Ova Politika kolačića je podložna promenama. U slučaju značajnih izmena (npr. uvođenje novih kolačića izvan kategorije neophodnih/obaveznih),
                Udruženje će sve Registrovane korisnike obavestiti o izmenama na aktivan način, na primer putem imejla ili jasno uočljivih obaveštenja (push ili pop up notifikacija). Izmenjena Politika privatnosti stupa na snagu danom donošenja, odnosno po davanju saglasnosti korisnika ili u roku od osam dana od dana objavljivanja na Platformi, te ja za ovo vreme Korisnik dužan da se upozna sa dokumentom. Udruženje može da odredi i duži rok stupanja na snagu. Ukoliko izmene nisu, pak, prihvatljive za Korisnika, dužan je da pokrene proces brisanja svog naloga, odnosno da prestane sa korišćenjem Platforme. Udruženje zadržava pravo da od Korisnika zatraži ponovno prihvatanje Politike privatnosti za korišćenje Platforme, te da uskrati pristup Korisniku koji ne prihvati promenjenu Politiku privatnosti.
                <br><br>
                U slučaju pravnih sporova u vezi sa ovom Politikom kolačića primenjuje se zakonodavstvo Republike Srbije.
                <br><br>
                Ugovorne strane će nastojati da sve sporove ili nesuglasice koji nastanu iz ili u vezi sa ovom
                Politikom kolačića rešavaju mirnim putem. Ukoliko to nije moguće, sporovi će se rešavati pred mesno i stvarno nadležnim sudom u R. Srbiji.
                <br><br>
                U Beogradu, 28.06.2021. godine

              </p>

              <h4 class="title_s title_lightblue mt-4">
                PITANJA, KOMENTARI, SUGESTIJE, PRIMEDBE:
              </h4>

              <p class="text_l mt-3">


                U svakom trenutku, stojimo na raspolaganju za Vaša pitanja, komentare, sugestije, zapažanja, ideje, primedbe i prigovore, te nas
                slobodno kontaktirajte u vezi sa ovom Politikom kolačića, <router-link to="/privacy-policy">Politikom privatnosti</router-link>, <router-link to="/terms-and-confitions">Pravilima</router-link>
                 i Platformom, putem Internet prezentacije www.serbianresearchers.com.

              </p>

            </div>
          </div>
        </div>
      </section>
    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"


export default{
  name: "TermsAndConditions",
  components: {
    Navbar,
    Footer
  }
}
</script>
